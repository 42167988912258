import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Checkup from "../../components/checkup"
import { Link } from "gatsby"
import OtherCaseStudy from "../../components/other-case"

import gogleads from "../../assets/img/gogleads.png"
import criteo from "../../assets/img/criteo.jpg"
import fbook from "../../assets/img/fbook.png"
import tiktok from "../../assets/img/tiktok-new.png"
import twitter from "../../assets/img/twitter-new.png"
import fullamobil from "../../assets/img/fulla-mobil.png"
import webtrafik from "../../assets/img/web-trafik.png"
import realtime from "../../assets/img/realtime2.png"

import melisa from "../../assets/img/teams/18.jpg"
import serife from "../../assets/img/teams/20.jpg"
import duygu from "../../assets/img/teams/41.jpg"






const ReferancesDetail = () => (
    <Layout>
        <SEO title="Fullamoda Başarı Hikayesi" />
        <div className="referances-detail py60 container" style={{ maxWidth: 950}}>
            <div className="col-md-12 detail-left" >
                <b>BAŞARI HİKAYESİ</b>
                <h1>Fullamoda</h1>
                <p>Kalıplara sığmayarak güncel modaya ayak uyduran Fulla Moda; kadın, erkek ve çocuk giyimde trend ürünleri Türkiye’de 70+ fiziksel mağazası ve e-ticaret platformuyla tüketiciyle buluşturuyor. Fullamoda online alışveriş sitesinde de zengin ürün yelpazesi kullanıcının beğenisini kazanıyor. </p>
            </div>
            <div className="col-md-6 detail-left">
                <div className="provided">
                    <h3>Verilen Hizmetler</h3>
                    <div className="provided-box">
                        <div className="box">
                            <Link to="/digital-ads/"></Link>
                            <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 76 76">
                                <g id="Icons-_-Shopping-_-Cart_1" data-name="Icons-/-Shopping-/-Cart#1" transform="translate(0.452 0.373)">
                                    <rect id="bound" width="76" height="76" transform="translate(-0.452 -0.373)" fill="none" />
                                    <path id="Combined-Shape" d="M52.453,31.233l-2.2,13.227-.008.046a3.161,3.161,0,0,1-3.675,2.544L10.63,40.517a3.161,3.161,0,0,1-2.474-2.241L2.389,18.088a3.161,3.161,0,0,1,3.039-4.029H48.813l1.938-8.306a3.729,3.729,0,0,1,3.632-2.882h6.527a3.729,3.729,0,1,1,0,7.459H57.342Z" transform="translate(4.899 6.203)" fill-rule="evenodd" opacity="0.3" />
                                    <path id="Combined-Shape-2" data-name="Combined-Shape" d="M9.741,27.482a4.741,4.741,0,1,1,4.741-4.741A4.741,4.741,0,0,1,9.741,27.482Zm28.447,0a4.741,4.741,0,1,1,4.741-4.741A4.741,4.741,0,0,1,38.188,27.482Z" transform="translate(10.804 38.894)" fill-rule="evenodd" />
                                </g>
                            </svg>
                            Digital Ads
                        </div>
                        <div className="box">
                            <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 76 76">
                                <g id="Icons-_-Design-_-Sketch" data-name="Icons-/-Design-/-Sketch" transform="translate(0.015 -0.307)">
                                    <rect id="bound" width="76" height="76" transform="translate(-0.015 0.307)" fill="none" />
                                    <path id="Path-48" d="M13.728,3H58.276L71,18.91H1Z" transform="translate(2.182 6.546)" fill-rule="evenodd" opacity="0.3" />
                                    <path id="Path-48-Copy" d="M71,8,36,46.184,1,8Z" transform="translate(2.182 17.456)" fill-rule="evenodd" />
                                </g>
                            </svg>
                            Dijital Danışmanlık
                        </div>
                    </div>
                </div>
                
            </div>
            <div className="col-md-6 project-details">
                    <h3>Künye</h3>
                    <div className="project-details_list">
                        <ul>
                            <li>Marka Stratejisti:</li>
                            <li>Marka Yöneticisi:</li>
                            <li>Dijital Reklam Uzmanı:</li>
                        </ul>
                        <ul>
                            <li><img src={melisa}  alt="Melisa Akınsa"/>Melisa Akınsal</li>
                            <li><img src={duygu} alt="Duygu Ekim Serteser" />Duygu Serteser</li>
                            <li><img src={serife} alt="Şerife Aytekin" />Şerife Aytekin</li>
                        </ul>
                    </div>

            </div>
        </div>
        <div className="container">

            <div className="content-section content row" >
                <div className="col-md-6">
                    <img  src={fullamobil} alt="iki laptop bilgisayar ekranında tcdd taşımacılık web sitesi görüntüleniyor" />
                </div>
                <div className="col-md-6">
                    <p className="small" style={{ maxWidth: 480, color:"#ea4f6a", textAlign:"left" }} >Başarı Hikayesi</p>
                    <h3 style={{ maxWidth: 480, fontSize: 25, textAlign:"left" }}><b>Fullamoda X Flatart</b></h3>
                    <p style={{ maxWidth: 480, textAlign:"left" }} >Aktif e-ticaret kampanyaları ve web sitesi yönetimi ile müşterisine her zaman dinamik bir alışveriş deneyimi sunan Fullamoda ile Flatart’ın yolu ilk defa Black Friday kampanyası ile kesişti. </p>
                    <p style={{ maxWidth: 480, textAlign:"left"}}>Yapılan indirim kampanyası ile birlikte yeni kullanıcı kazanımı hedeflenirken bunun yanı sıra özel indirim kodları ile Retargeting reklam aksiyonları da alındı. Zorlu bir sektörde dev aktörlerle yarıştığımızın bilincinde olarak, trend reklam modelleri ve real time çözümlerimiz ile CRO yönelik aksiyonlar alarak başarılı bir kampanyaya imza attık.</p>
                    
                </div>
            </div>
            <div className="text-center">
                <span className="small">Başarı Hikayesi</span>
                <h2 style={{ margin: '7px auto -20px', maxWidth: 900 }}>1’e 5 ROI Kazanımı </h2>
            </div>
            <div className="referances-detail py60" >
                <div className="referances-boxs" >
                    <div className="boxs">
                            <div className="box" style={{ width: 230 }}>
                                <span>+%114.70</span>
                                <p>E-ticaret Dönüşüm Oranında Artış</p>
                            </div> 
                            <div className="box" style={{ width: 230 }} >
                                <span>+%160.55</span>
                                <p>Web Sitesi Satış Adedi Artışı</p>
                            </div>
                            <div className="box" style={{ width: 230 }} >
                                <span>+%279.20</span>
                                <p>Ciro Artışı</p>
                            </div>
                            <div className="box" style={{ width: 230 }}>
                                <span>+%22.27</span>
                                <p>Oturum Sayısı Artışı</p>
                            </div>
                    </div>
                </div>
            </div>

            <div className="text-center">
            <span className="small">Başarı Hikayesi</span>
                <h2 style={{ margin: '7px auto 20px', maxWidth: 900 }}><b>CRO Destekleyici Satış Kanalları</b> </h2>
                <div className="col-md-4">
                    <img style={{ maxHeight: 120}} src={gogleads} alt="iki laptop bilgisayar ekranında tcdd taşımacılık web sitesi görüntüleniyor" />
                    <p style={{ fontSize: 25, margin: '10px auto 30px'}}>Google Ads</p>
                </div>
                <div className="col-md-4">
                    <img style={{ maxHeight: 120}} src={criteo} alt="iki laptop bilgisayar ekranında tcdd taşımacılık web sitesi görüntüleniyor" />
                    <p style={{ fontSize: 25, margin: '10px auto 30px'}}>Criteo</p>
                </div>
                <div className="col-md-4">
                    <img style={{ maxHeight: 120}} src={fbook} alt="iki laptop bilgisayar ekranında tcdd taşımacılık web sitesi görüntüleniyor" />
                    <p style={{ fontSize: 25, margin: '10px auto 30px'}}>Facebook&Instagram Ads</p>
                </div>
            </div>

            <div className="text-center">
                <p style={{ fontSize: 18, margin: '30px auto 30px', maxWidth: 800 }}>Satış hedefinin yanı sıra marka bilinirliliğinin de hedeflendiği reklam kampanyalarında TikTok, Twitter mecralarında da reklam yayını yapılmıştır.  Video yayınları ile birlikte <b>1.5M+</b> reklam videosu izlenme aksiyonu alındı.</p>
                <div className="col-md-3"></div>
                <div className="col-md-3">
                    <img style={{ maxHeight: 120}} src={tiktok} alt="iki laptop bilgisayar ekranında tcdd taşımacılık web sitesi görüntüleniyor" />
                </div>
                <div className="col-md-3">
                    <img style={{ maxHeight: 120}} src={twitter} alt="iki laptop bilgisayar ekranında tcdd taşımacılık web sitesi görüntüleniyor" />
                </div>
                <div className="col-md-3"></div>  
            </div>
            
        </div>

        <div className="container">
            <div className="text-center">
                <h2 style={{ margin: '70px auto 20px', maxWidth: 900 }}>Kampanya Dönemi Web Sitesi Trafiği</h2>
                <img style={{ width: '90%', margin: '0 auto' }} src={webtrafik} />
                <h2 style={{ margin: '20px auto 20px', maxWidth: 900 }}>Dijital Danışmanlık & Realtime Aksiyon</h2>
                <p style={{ fontSize: 18, margin: '30px auto 30px', maxWidth: 800 }}>Black Friday gibi büyük bir kampanya dönemi ardından, dijitalde giderek artan “2022 Zamları” gündemini değerlendirerek sektördeki rakiplerimizden ayrıştığımız “Bu Fiyatlar Bir Daha Gelmez!” kampanyasını organize ettik ve yönettik.</p>
                <img style={{ width: '70%', margin: '0 auto' }} src={realtime} />
                <p style={{ fontSize: 18, margin: '30px auto 30px', maxWidth: 800 }}>Kullanıcıların sitemizde ve dijitalde aradığı ürünler odağında CRO yönelik aldığımız aksiyonlar sonucunda 2000+ ürün satışı gerçekleştirdik.</p>

            </div>

        </div>




        <OtherCaseStudy />

        <Checkup />
    </Layout >
)

export default ReferancesDetail
